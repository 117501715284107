/// <reference path="/node_modules/jquery/dist/jquery.js" />
/// <reference path="/node_modules/onepage-scroll/jquery.onepage-scroll.js" />
/// <reference path="w315.js" />
/// <reference path="myBrowser.js" />

var menu2MinOneColumnElemnts = 5;
var events = [];

events.push(function () { columnizeMenu2() });
//events.push(function () { blinkedEuroviaNews() });
//$("body").css("margin-top", $("header").outerHeight());

events.push(function () {
    if (jQuery().euroviaSlider) {
        $("#slider").euroviaSlider();
    }
});

//Gestion print
events.push(function () { $(".print").css("cursor", "pointer").click(function (e) { window.print(); }); });
//Gestion font-size -0.1
events.push(function () { $(".decreaseFont").css("cursor", "pointer").click(function (e) { zoom(-0.1); }); });
//Gestion font-size +0.1
events.push(function () { $(".increaseFont").css("cursor", "pointer").click(function (e) { zoom(0.1); }); });

//Gestion des shares
events.push(function () { $(".share").euroviaShare(); });

//Gestion vidéo arrière plan
events.push(function () {
    if (typeof videoId !== 'undefined') {
        $("#slider").YTPlayer({
            fitToBackground: true,
            videoId: videoId,
            //height: $(this).innerHeight(),
            //width: $(this).innerWidth(),
            callback: function () {
                //events.push(function () {
                //    console.log("playerFinshed");
                //});
            }
        });

        if (videoId.indexOf("vimeo") > -1) {
            var css = $(".ytplayer-player").attr("style");
            $(".ytplayer-player").replaceWith("<iframe id='vimeoVideo' src='" + videoId + "?api=1&background=1&portrait=0&autoplay=1&loop=1&player_id=vimeoVideo' style='' ></iframe>");
            $("#vimeoVideo").attr("style", css);
            $("#vimeoVideo").css("height", "110%");
            $("#vimeoVideo").css("width", "123%");
            $("#vimeoVideo").css("top", "-5%");
            $("#vimeoVideo").css("left", "-11.5%");

            iframe = $('#vimeoVideo')[0];
            player = $f(iframe);
            player.addEvent('ready', function () {
                player.api('setVolume', 0);
            });
        }
    }
});

events.push(function () {
    //window.addEventListener("scroll", function (e) {
    var height = $('header').length ? $('header').outerHeight() : 0;
    var border = $('header').length ? parseInt($('header').css("border-bottom-width").replace("px", "")) : 0;
    var offset = Math.ceil(height + border) / 2;
    var running = false;
    var reduced = false;

    $(window).scroll(function () {
        var scroll = getCurrentScroll();
        //console.log("runnning = " + running);
        //console.log("scroll = " + scroll);

        if (!running) {
            if (scroll > 150 && !reduced) {
                running = true;
                $('header').velocity({ "top": -offset + border }, 200);
                if ($(".navigation-top-area").length) {
                    offset = $(".navigation-top-area").outerHeight();
                }
                $(".breadcrumb-container").velocity({ "top": offset }, {
                    duration: 200,
                    complete: function () {
                        running = false;
                        reduced = true;
                    }
                });
                $(".warning-breadcrumb").velocity({ "top": offset }, {
                    duration: 200
                });
                $("#scrolledItem").fadeIn(200);
            } else if (scroll <= 150 && reduced) {
                running = true;
                $('header').velocity({ "top": 0 }, 200);
                //$('#headerEntity').velocity({ "top": 30 }, 200);
                $(".breadcrumb-container").velocity({ "top": height }, {
                    duration: 200,
                    complete: function () {
                        $(".breadcrumb-container").css("top", "");
                        running = false;
                        reduced = false;
                    }
                });
                $(".warning-breadcrumb").velocity({ "top": height + 30 }, {
                    duration: 200,
                    complete: function () {
                        $(".warning-breadcrumb").css("top", "");
                    }
                });
                $("#scrolledItem").fadeOut(200);
            }
        }
    });
});

events.push(function () {
    $('.ajax-more-load').removeData('loading').on('click', function (event) {
        event.preventDefault();
        var $load = $(this);

        // lock the link
        //if ($load.data('loading')) return;
        //$load.data('loading', true);

        var $container = $load.closest('.ajax-more-ctnr');
        if ($container.length === 0) return;

        var $href = $load.attr('href');
        var $savedHtml = $load.html();
        $load.html('. . .');

        var $html = $('<div></div>');
        $html.load($href, function () {
            // if the container is gone, no need to do anything
            if (!jQuery.contains(document.documentElement, $container[0])) return;

            // check for data
            // found = update the link and remove data from html
            // else, kill the link
            var $data = $html.find('data');
            if ($data.length > 0) {
                var $nurl = $data.data('url');
                $load.html($savedHtml).attr('href', $nurl);
                $data.remove();
            } else {
                $load.hide();
            }

            // load into container
            // .children() does not work on text nodes, must use .contents()
            // detach to be safe and not create copies
            $html.contents().each(function () {
                $(this).detach().appendTo($container);
                // activate here because $container is was empty
                eurovia.plugFlyBoxOpen($(this));
            });

            // unlock the link
            $load.removeData('loading');

            //var ellipsis = $(".ellipsis");
            //var nrLines = 3;
            //var ellHeight = parseInt(ellipsis.css('line-height'), 10) * nrLines;

            //ellipsis.dotdotdot({
            //    height: ellHeight // this is the number of lines
            //});
        });
    });
});

$(window).on('load', function () {
    var step = 100 / events.length;
    var percent = 0;
    var i = 0;

    while (events.length > 0 || i < events.length) {
        i++;

        var currentFunction = (events.shift()); // removes first element from array

        if (currentFunction) {
            $.when(currentFunction()).then(function () {
                setTimeout(function () {
                    percent += step;
                    $("#progress").text(Math.round(percent));

                    if (events.length === 0 || percent === 0) {
                        $(".splashScreen").show().delay(750).fadeOut(500);
                    }
                }, 100 + i * 30);
            });
        } else {
            console.log(currentFunction);
        }
    }

    angular.module('ui.bootstrap.carousel')
        .controller('CarouselController', [function () { }])
        .directive('carousel', [function () { return {}; }]);
});

function sleep(seconds) {
    console.log("sleeping for " + seconds);
    var waitUntil = new Date().getTime() + seconds * 1000;
    while (new Date().getTime() < waitUntil) true;
}

//GESTION CAROUSEL POUR TOUT LE SITE
function setCarousel(obj, nbPerPage, isLoop, isInPopup, smartSpeed, autoPlayTimeout, autoplay, autoplayHoverPause) {
    isInPopup = typeof isInPopup !== 'undefined' ? isInPopup : false;
    smartSpeed = typeof smartSpeed !== 'undefined' ? smartSpeed : 1500;
    autoPlayTimeout = typeof autoPlayTimeout !== 'undefined' ? autoPlayTimeout : 6000;
    autoplay = typeof autoplay !== 'undefined' ? autoplay : true;
    autoplayHoverPause = typeof autoplayHoverPause !== 'undefined' ? autoplayHoverPause : true;

    var owl = $(obj);
    //console.log("owl-caoursel");
    owl.owlCarousel({
        autoplay: autoplay,
        autoplayTimeout: autoPlayTimeout,
        autoplayHoverPause: autoplayHoverPause,
        lazyLoad: true,
        items: nbPerPage,
        nav: true,
        navText: [
            "<div class='left carousel-control' style='z-index: 1'><i class='glyphicon glyphicon-chevron-left'></i></div>",
            "<div class='right carousel-control' style='z-index: 1'><i class='glyphicon glyphicon-chevron-right'></i></div>"
        ],
        loop: isLoop,
        smartSpeed: smartSpeed
    });

    owl.mouseover(function (e) {
        owl.stop(true);
        //owl.trigger('stop.owl.autoplay');
    });

    owl.mouseout(function (e) {
        $(".owl-stage").css("animation-play-state", "running");
        $(".owl-stage").css("-webkit-animation-play-state", "running");
        //owl.trigger('play.owl.autoplay');
    });
    /*
    $(".carousel-control").each(function (i, item) {
        $(item).css("cursor", "pointer");

        if (isInPopup) {
            $(item).appendTo(owl);
        }

        $(item).click(function (e) {
            e.preventDefault();
        });
    });
    */

    $(obj).find(".owl-item").css({
        display: "inline-block",
        float: "left"
    });
}

function setCarouselSimple(obj, nbPerPage, isLoop, isVideo) {
    isLoop = typeof isLoop !== 'undefined' ? isLoop : true;
    isVideo = typeof isVideo !== 'undefined' ? isVideo : false;
    //console.log(obj + " -> isLoop ? " + isLoop);
    var owl = $(obj);
    if (isVideo) {
        // 392
        owl.owlCarousel({
            video: true,
            autoplay: 3000,
            autoplayHoverPause: true,
            lazyLoad: true,
            items: nbPerPage,
            loop: isLoop,
            smartSpeed: 1500,
            nav: true,
            navText: [
                "<a class='left carousel-control' style='z-index: 1'><i class='glyphicon glyphicon-chevron-left'></i></a>",
                "<a class='right carousel-control' style='z-index: 1'><i class='glyphicon glyphicon-chevron-right'></i></a>"
            ]
        });
    }
    else {
        owl.owlCarousel({
            autoplay: 3000,
            autoplayHoverPause: true,
            lazyLoad: true,
            items: nbPerPage,
            loop: isLoop,
            smartSpeed: 1500,
            nav: true,
            navText: [
                "<a class='left carousel-control' style='z-index: 1'><i class='glyphicon glyphicon-chevron-left'></i></a>",
                "<a class='right carousel-control' style='z-index: 1'><i class='glyphicon glyphicon-chevron-right'></i></a>"
            ]
        });
    }
}

function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

function zoom(zoom) {
    $("html").children().each(function () {
        var k = parseInt($(this).css('font-size'));
        var redSize = k * (1 + zoom);
        $(this).css('font-size', redSize);
    });
}

function splitUl(element) {
    var $element = $(element);
    var i = Math.ceil($element.children().length / 2);
    var detachElements = ">li:gt(" + i + ")";
    var $clone = $element.clone();
    $clone.empty();
    $element.find(detachElements).detach().appendTo($clone);
    $clone.insertAfter(element);
}

function columnizeMenu2() {
    $('ul .menu-2 ul.classic').each(function () {
        $this = $(this);
        var $childs = $this.find(">li");
        if ($childs.length > menu2MinOneColumnElemnts) {
            splitUl(this);
        }
    });
}

//function blinkedEuroviaNews() {
//    setTimeout(function () {
//        $("#banner-news").toggleClass("inverted");
//        blinkedEuroviaNews();
//    }, 2000);
//}